<template>
  <ul class="person-profile-looks" v-infinite-scroll="loadMoreLooks">
    <li v-for="look in looks" :key="look.id">
      <look-card :look="look" :show-menu="false"></look-card>
    </li>
  </ul>
</template>

<script>
  import profileService from '@/services/queries/profileQueries.js'
  import LookCard from '@/components/Shared/LookCard/LookCard.vue'

  export default {
    props: {
      person: {
        required: true
      }
    },

    components: {
      LookCard
    },

    data() {
      return {
        looks: [],

        limit: 50,
        offset: 0,
        total: 0
      }
    },

    methods: {
      loadLooks(append = false) {
        let params = {
          author_id: this.person.id,
          limit: this.limit,
          offset: this.offset,
          is_public: true
        }

        this.loading = true

        profileService.getLooks(this.person.id, params).then(data => {
          this.total = data.total_count

          let looks = data.items

          if (append) {
            this.looks.push(...looks)
          } else {
            this.looks = looks
          }

          this.loading = false
        })
      },

      loadMoreLooks() {
        this.offset = this.offset + this.limit

        if (this.offset > this.total) {
          return
        }

        this.loadLooks(true)
      }
    },

    watch: {
      person: {
        handler() {
          this.looks = []
          this.offset = 0

          this.loadLooks()
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss">


  .person-profile-looks {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0;
    padding: 20px;
    margin: 0;
    list-style-type: none;

    & > li {
      margin: 10px;
    }
  }
</style>
